import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import PdfButton from "../components/PdfButton"
import {
  certificationEiaTest,
  requirementsEia,
  eiaDocuments,
} from "../data/certification-efp"
import parse from "react-html-parser"
import Iva from "../components/Iva"
import TitlePage from "../components/TitlePage"
import { useAxiosClient } from "../service/useAxiosClient"
import AnnouncementEfp from "../components/Announcement/AnnouncementEfp"
import TutorialBtn from "../components/TutorialBtn"

export default function CertificationTestEIA({ location }) {
  const [test, setTest] = useState()
  const [price, setPrice] = useState([])

  const [{ data: dataTest }] = useAxiosClient({
    url: `/exams/dates/web/next-calls/?exam_type=EIA`,
    method: "GET",
  })

  useEffect(() => {
    if (dataTest && dataTest.EIA) {
      const [test] = dataTest.EIA.calls
      setTest(test)
    }
  }, [dataTest])

  const renderAnnouncement = () => {
    if (!test) return
    return (
      <div className="Certification-content">
        <AnnouncementEfp
          title={test?.call_text}
          date={test?.exams_date_text}
          link={test?.inscription_link}
        />
      </div>
    )
  }

  const [{ data: dataPrice }] = useAxiosClient({
    url: `/professional-certificates/web/prices/`,
    method: "GET",
  })

  useEffect(() => {
    if (dataPrice && dataPrice.length) {
      const priceFind = dataPrice.find(item => item.pk === 5)
      setPrice(priceFind)
      console.log(`dataPrice`, dataPrice)
    }
  }, [dataPrice])

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones", to: "/certificacion" },
    {
      label:
        "Exámenes certificación EFPA <em>European Investment Assistant</em> (EIA)",
    },
  ]
  const renderContent = () => {
    return (
      certificationEiaTest &&
      certificationEiaTest.map(item => {
        return (
          <div className="efpa-content">
            <TitleBlock
              className="Certification-title-block"
              title={item.title}
              icon={item.icon}
            />
            <div className="Certification-container">{parse(item.content)}</div>
          </div>
        )
      })
    )
  }
  const renderContentRight = () => {
    return (
      requirementsEia &&
      requirementsEia.map(item => {
        return (
          <div className="efpa-content">
            <TitleBlock
              className="Certification-title-block"
              title={item.title}
              icon={item.icon}
            />
            <div className="Certification-container">{parse(item.content)}</div>
          </div>
        )
      })
    )
  }
  const renderDocuments = () => {
    return (
      eiaDocuments &&
      eiaDocuments.map(item => {
        return (
          <PdfButton
            isExternal
            link={item.link}
            className="mb-2"
            text={item.name}
          />
        )
      })
    )
  }
  return (
    <Layout>
      <TitlePage
        title="Exámenes certificación EFPA European Investment Assistant (EIA)"
        url={location.pathname}
      />
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="Exámenes certificación EFPA <em>European Investment Assistant</em> (EIA)"
          icon="far fa-id-card"
        />
        <div className="CertificationTestEFP">
          <div className="row efpa-row">
            <div className="col-12 col-md-6 Certification-padding-r">
              {renderContent()}
              <TutorialBtn />
              <div className="efpa-content">
                <TitleBlock
                  className="Certification-title-block"
                  title="Simulación examen"
                  icon="fas fa-file-alt"
                />
                <PdfButton
                  isExternal
                  link="/docs/simulacion_examen_EIA_parte_teorica.pdf"
                  text="Simulación parte teórica"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 Certification-padding-l">
              {renderContentRight()}
              <div className="Certififcation-content efpa-content">
                <TitleBlock
                  className="Certification-title-block"
                  title="Documentación"
                  icon="fas fa-file-alt"
                />
                {renderDocuments()}
              </div>
              <div className="Certification-content">
                {renderAnnouncement()}
              </div>
              <TitleBlock
                className="Certification-title-block mt-3"
                title="Coste"
                icon="d-none"
              />
              <Iva
                price={`${price.price} € (IVA incluido)`}
                text="Consultar en EFPA los descuentos para entidades colaboradoras."
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
